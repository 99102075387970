import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../Assets/avatar.svg";
import "../style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Hi, I am a <b className="purple"> mobile developer </b> from Brazil, currently, working as <b className="purple"> iOS Developer. </b> Since I was a tennager, I've been fascinated with software development. Not just the software itself, but the hardware that makes it happen. 🤷‍♂️
              <br />
              <br /> I have a rich experience working with customers from around the world and the work I provide is of the <b className="purple"> highest quality </b>, fully responsive, and tested in a wide range of devices. My code is well documented and easy to read, which means it can be maintained and scaled in the future.
              <br />
              <br />For over five years I've been working with native technologies for mobile development and also I have a great experience with backend development and database structures.
              <br />
              <br />I am fluent in
              <i>
                <b className="purple"> C++, Java, Swift and Kotlin. </b>
              </i>
              <br />
              <br />
              My current focus is on &nbsp;
              <i>
                <b className="purple">
                Mobile and Back End development.
                </b>
              </i>
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            {/* <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt> */}
            <img src={myImg} className="img-fluid" alt="avatar" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/GuilhermeDeconto"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-github"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/guilherme.deconto.3/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/guilhermedeconto/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/decontogui/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:guilherme.deconto@acad.pucrs.br"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
