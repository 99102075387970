import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import homeLogo from "../Assets/home-main.svg";
import Particle from "./Particle";
import Home2 from "./Home2";
import Typed from "react-typed";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There! <span className="wave">👋🏻</span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Guilherme Dall'Agnol Deconto</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left"}}>
              <span className="purple" style={{fontSize: 50}}> 
              <Typed
                    strings={[
                      "iOS Developer",
                      "Android Developer",
                      "Back End Developer",
                      "Software Engineer"
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  /> 
                  </span>
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img src={homeLogo} alt="home pic" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
