import React, { useState, useEffect } from 'react';
import '../style/App.css'

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, workData, contactData, footerData } from '../mock/data';
import Home from './Home';
import Pre from './Pre';
import Footer from './Footer';
import About from './About/About';
import Projects from './Projects/Projects';
import NavBar from './Navbar';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [work, setWork] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      upadateLoad(false);
    }, 1200);
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setWork([...workData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, work, contact, footer }} className="App" id="scroll">
      <Pre load={load} />
      <NavBar />
      <Home />
      <About />
      <Projects />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
