import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <Container style={{height:"100%", width:"100%"}}>
            <li className="social-icons" style={{height:"100%", width:"100%"}}>
              <a href="https://www.freepik.com/vectors/work" style={{ color: "white", justifyContent:"center", alignItems:"center", height:"100%", display:"flex" }}>Vectors by Freepik</a>
            </li>
          </Container>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3> © {year} - Guilherme Dall'Agnol Deconto</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/GuilhermeDeconto"
                style={{ color: "white" }}
              >
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.facebook.com/guilherme.deconto.3/"
                style={{ color: "white" }}
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/guilhermedeconto/"
                style={{ color: "white" }}
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/decontogui/"
                style={{ color: "white" }}
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="mailto:guilherme.deconto@acad.pucrs.br"
                style={{ color: "white" }}
              >
                <i className="fa fa-envelope"></i>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
