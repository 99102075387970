import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import "bootstrap/dist/css/bootstrap.min.css";

import leaf from "../../images/safra.png";
import topazio from "../../images/topazio.png";
import rci from "../../images/rci.png";
import digimais from "../../images/digimais.jpg";
import omni from "../../images/omni.png";
import guarida from "../../images/guarida.jpg";

function Projects() {
  return (
    <Container fluid className="project-section" id="projects">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Safra National Bank of NY"
              description="Safra National Bank of New York is a commercial banking institution engaged in international banking operations, as well as domestic business activities emphasizing short and intermediate term financing for business ventures in industry and trade."
              link="https://www.safra.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={omni}
              isBlog={false}
              title="Omni Banco & Financeira"
              description="Omni Banking & Financial, present in the market for 25 years, is a solid institution and recognized for having one of the best credit services in Brazil."
              link="https://www.omni.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={guarida}
              isBlog={false}
              title="Guarida Imóveis"
              description="Focused on the principles of modernity, vision of the future and excellence in services, in 2000 the company became the first real estate company in the southern region of Brazil to be internationally certified by ISO 9001 in rental management, condominiums and intermediation in the purchase and sale of properties."
              link="https://www.guarida.com.br/"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={digimais}
              isBlog={false}
              title="Banco Digimais"
              description="Banco digimais were born as a traditional bank and today, more than 35 years later, we have become 100% digital. Time has brought us experience, solidity and credibility, fundamental assets that we have to offer you, together with the security of our financial products and personalized service."
              link="https://www.bancodigimais.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={topazio}
              isBlog={false}
              title="Banco Topázio"
              description="B2B digital bank, with national operations and specialized in the foreign exchange and bank as a service segments. Through our expertise in technology and attentive to the evolution of the financial sector, we develop quality products and services pertinent to the needs of our clients."
              link="https://www.bancotopazio.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rci}
              isBlog={false}
              isApp={true}
              title="RCIDirect"
              description="All the features and information necessary for the best service in the dealer network."
              link="https://apps.apple.com/br/app/rci-direct-brasil/id1481153154"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
